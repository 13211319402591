import React from 'react'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

function Sold() {
  return (
    <div className='h-screen '>
        <Navbar/>
        <div className='bg-black text-black text-left justify-items-center p-10'>
            <div className='bg-white justify-center p-2 rounded-lg'>
                <p className=' text-center underline font-bold'>PAYMENT NOTICE</p>
                <div className=' p-2 gap-4'>
                    <p>Payment Already in progress ...</p>
                    <p>Payment will be made to beat owners account in 24 hours.</p>
                    <p>Thank you for using our service.</p>
                    <div className='bg-gray-200 p-2 font-serif font-bol mt-2 rounded-lg p-2'>
                        <p>NOTE:</p>
                        <p>For further inquires!!</p>
                        <p>please contact our customer service for verification and personalization to claim your Reward!!</p>
                    </div>
                    
                </div>
            </div>
        </div>
      <Footer/>
    </div>
  )
}

export default Sold;