import  React  from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import businesscard from "../components/Businesscard";
import { Link } from "react-router-dom";

function Home() {
  return (
    <div>
        <Navbar/>
        <div className=" bg-black text-center justify-center py-10 text-gray-400">
            <div>
                <p>Number One Best Selling Music NFT Platform WORLDWIDE</p>
                <p>Music NFT Sales</p>
                <p>Beat NFT Sales</p>
                <p>Blockchain Music/Beat Aunctioning</p>
            </div>
            <div className='mt-10 '>
              <h1 className="underline font-bold font-serif text-xl text-yellow-300">OUR POPULAR COLLECTIONS</h1>
              <div className=' mt-10 p-2 z-10 grid grid-cols-2 gap-5 md:grid-cols-3 md:gap-10 md:pl-16 md:justify-evenly'>
                <div className='rounded shadow shadow-green-600 sm:w-48 bg-gray-600  relative'>
                  <img src="image/1.jpeg" alt='' className='w-full h-32 sm:h-48 object-cover'/>
                  <div className=" justify-between font-semibold text-white ">
                      <p className="uppercase"> Panagiotis</p>
                      <p>Drizzy</p>
                  </div>
                  <audio controls className="h-10 w-full p-2">
                  <source src="audio/1.mpeg"/>
                  </audio>
                  <div className="p-2">
                    <Link to="/Sold">
                    <button className='bg-orange-600 font-bold rounded-lg text-black px-4 p-2'>SOLD</button>
                    </Link>
                  </div>                    
                  <div className="absolute top-0 bg-white rounded-lg p-1 m-4 font-bold">
                    <span>$1450</span>
                  </div>
                </div>

                <div className='rounded shadow-green-600 shadow sm:w-48 bg-gray-600 overflow-hidden relative'>
                    <img src="image/2.jpeg" alt='' className='w-full h-32 sm:h-48 object-cover'/>
                    <div className=" justify-between font-semibold  text-white ">
                        <p className="uppercase">Philip</p>
                        <p>Chill Melody</p>
                    </div>
                    <audio controls className="h-10 w-full p-2">
                    <source src="audio/2.mpeg"/>
                    </audio>
                    <div className="p-2">
                      <Link to ="/Sold">
                      <button className='bg-orange-600 font-bold rounded-lg text-black px-4 p-2'>SOLD</button>
                      </Link>
                    </div>
                    <div className="absolute top-0 bg-white rounded-lg p-1 m-4 font-bold">
                        <span>$1500</span>
                    </div>
                </div>

                <div className='rounded shadow-green-600 shadow sm:w-48 bg-gray-600 overflow-hidden relative'>
                  <img src="image/3.jpeg" alt='' className='w-full h-32 sm:h-48 object-cover'/>
                  <div className=" justify-between font-semibold text-white">
                    <p className="uppercase">Didah</p>
                    <p>Fast Trap</p>
                  </div>
                  <audio controls className="h-10 w-full p-2">
                      <source src="audio/3.mpeg"/>
                  </audio>
                  <div className="p-2">
                  <Link to="/Sold">
                    <button className="bg-orange-600 font-bold rounded-lg text-black px-4 p-2 mb-2">SOLD</button>
                  </Link>
                  </div>
                  <div className="absolute top-0 bg-white rounded-lg p-1 m-4 font-bold">
                  <span>$1500</span>
                  </div>
                </div>

                <div className='rounded shadow-green-600 shadow sm:w-48 bg-gray-600 overflow-hidden relative'>
                  <img src="image/4.jpeg" alt='' className='w-full h-32 sm:h-48 object-cover'/>
                  <div className="justify-between font-semibold  text-white ">
                    <p className="uppercase">Flexed</p>
                    <p>Hellscare</p>
                  </div>
                  <audio controls className="h-10 w-full p-2">
                    <source src="audio/4.mpeg"/>
                  </audio>
                  <div className="p-2">
                    <Link to="/Sold">
                      <button className="bg-orange-600 font-bold rounded-lg text-black px-4 p-2 mb-2">SOLD</button>
                    </Link>
                  </div>
                  <div className="absolute top-0 bg-white rounded-lg p-1 m-4 font-bold">
                    <span>$1650</span>
                  </div>
                </div>

          <div className='rounded shadow-green-600 shadow sm:w-48 bg-gray-600 overflow-hidden relative'>
            <img src="image/5.jpeg" alt='' className='w-full h-32 sm:h-48 object-cover'/>
            <div className=" justify-between font-semibold text-white">
              <p className="uppercase"> Jake</p>
              <p> Passenger </p>
            </div>
            <audio controls className="h-10 w-full p-2">
            <source src="audio/5.mpeg"/>
            </audio>
            <div className="p-2">
              <Link to="/Buy">
              <button className='bg-green-600 font-bold rounded-lg text-black px-4 p-2'>BUY</button>
              </Link>
            </div>
            <div className="absolute top-0 bg-white rounded-lg p-1 m-4 font-bold">
              <span>$1650</span>
            </div>
          </div>

          <div className='rounded shadow-green-600 shadow sm:w-48 bg-gray-600 overflow-hidden relative'>
            <img src="image/6.jpeg" alt='' className='w-full h-32 sm:h-48 object-cover'/>
            <div className="justify-between font-semibold text-white ">
              <p className="uppercase"> jay</p>
              <p>Flawless</p>
            </div>
            <audio controls className="h-10 w-full p-2">
            <source src="audio/6.mpeg"/>
            </audio>
            <div className="p-2">
                <Link to="/Sold">
                <button className="bg-orange-600 font-bold rounded-lg text-black px-4 p-2 mb-2">SOLD</button>
                </Link>
            </div>
            <div className="absolute top-0 bg-white rounded-lg p-1 m-4 font-bold">
              <span>$1650</span>
            </div>
          </div>

          <div className='rounded shadow-green-600 shadow sm:w-48 bg-gray-600 overflow-hidden relative'>
            <img src="image/7.jpeg" alt='' className='w-full h-32 sm:h-48 object-cover'/>
            <div className="justify-between font-semibold text-white ">
              <p className="uppercase">Mikyle</p>
              <p>Fallen</p>
            </div>
            <audio controls className="h-10 w-full p-2">
            <source src="audio/7.mpeg"/>
            </audio>
            <div className="p-2">
                <Link to="/Sold">
                <button className="bg-orange-600 font-bold rounded-lg text-black px-4 p-2 mb-2">SOLD</button>
                </Link>
            </div>
            <div className="absolute top-0 bg-white rounded-lg p-1 m-4 font-bold">
              <span>$1600</span>
            </div>
          </div>

          <div className='rounded shadow-green-600 shadow sm:w-48 bg-gray-600 overflow-hidden relative'>
            <img src="image/8.jpeg" alt='' className='w-full h-32 sm:h-48 object-cover'/>
            <div className="justify-between font-semibold text-white ">
              <p className="uppercase">Daniele Cunaccia</p>
              <p>Cymatycs</p>
            </div>
            <audio controls className="h-10 w-full p-2">
            <source src="audio/8.mpeg"/>
            </audio>
            <div className="p-2">
              <Link to="/Buy">
              <button className='bg-green-600 font-bold rounded-lg text-black px-4 p-2'>BUY</button>
              </Link>
            </div>
            <div className="absolute top-0 bg-white rounded-lg p-1 m-4 font-bold">
              <span>$1450</span>
            </div>
          </div>

          <div className='rounded shadow-green-600 shadow sm:w-48 bg-gray-600 overflow-hidden relative'>
            <img src="image/9.jpeg" alt='' className='w-full h-32 sm:h-48 object-cover'/>
            <div className="justify-between font-semibold text-white ">
              <p className="uppercase">Daniel</p>
              <p>Rolling</p>
            </div>
            <audio controls className="h-10 w-full p-2">
            <source src="audio/9.mpeg"/>
            </audio>
            <div className="p-2">
              <Link to="/Buy">
                <button className="bg-green-600 font-bold rounded-lg text-black px-4 p-2 mb-2">BUY</button>
              </Link>
            </div>
            <div className="absolute top-0 bg-white rounded-lg p-1 m-4 font-bold">
              <span>$1400</span>
            </div>
          </div>

          <div className='rounded shadow-green-600 shadow sm:w-48 bg-gray-600 overflow-hidden relative'>
            <img src="image/10.jpeg" alt='' className='w-full h-32 sm:h-48 object-cover'/>
            <div className="justify-between font-semibold text-white ">
              <p className="uppercase">Leandro Zaccone</p>
              <p>Back to the Future</p>
            </div>
            <audio controls className="h-10 w-full p-2">
            <source src="audio/10.mpeg"/>
            </audio>
            <div className="p-2">
                <Link to="/Buy">
                <button className="bg-green-600 font-bold rounded-lg text-black px-4 p-2 mb-2">BUY</button>
                </Link>
            </div>
            <div className="absolute top-0 bg-white rounded-lg p-1 m-4 font-bold">
              <span>$1500</span>
            </div>
          </div>

          <div className='rounded shadow-green-600 shadow sm:w-48 bg-gray-600 overflow-hidden relative'>
            <img src="image/11.jpeg" alt='' className='w-full h-32 sm:h-48 object-cover'/>
            <div className="justify-between font-semibold text-white ">
              <p className="uppercase">Isaac Atkinson</p>
              <p>Hard</p>
            </div>
            <audio controls className="h-10 w-full p-2">
            <source src="audio/11.mpeg"/>
            </audio>
            <div className="p-2">
                <Link to="/Buy">
                <button className="bg-green-600 font-bold rounded-lg text-black px-4 p-2 mb-2">BUY</button>
                </Link>
            </div>
            <div className="absolute top-0 bg-white rounded-lg p-1 m-4 font-bold">
              <span>$1450</span>
            </div>
          </div>

          <div className='rounded shadow-green-600 shadow sm:w-48 bg-gray-600 overflow-hidden relative'>
            <img src="image/12.jpeg" alt='' className='w-full h-32 sm:h-48 object-cover'/>
            <div className="justify-between font-semibold text-white ">
              <p className="uppercase"> Diego Spaggiari</p>
              <p>Prova 999</p>
            </div>
            <audio controls className="h-10 w-full p-2">
            <source src="audio/12.mpeg"/>
            </audio>
            <div className="p-2">
                <Link to="/Sold">
                <button className="bg-orange-600 font-bold rounded-lg text-black px-4 p-2 mb-2">SOLD</button>
                </Link>
            </div>
            <div className="absolute top-0 bg-white rounded-lg p-1 m-4 font-bold">
              <span>$1500</span>
            </div>
          </div>

          <div className='rounded shadow-green-600 shadow sm:w-48 bg-gray-600 overflow-hidden relative'>
            <img src="image/13.jpeg" alt='' className='w-full h-32 sm:h-48 object-cover'/>
            <div className="justify-between font-semibold text-white ">
              <p className="uppercase">deizer</p>
              <p>Trap Zone</p>
            </div>
            <audio controls className="h-10 w-full p-2">
            <source src="audio/13.mpeg"/>
            </audio>
            <div className="p-2">
              <Link to="/Buy">
              <button className="bg-green-600 font-bold rounded-lg text-black px-4 p-2 mb-2">BUY</button>
              </Link>
            </div>
            <div className="absolute top-0 bg-white rounded-lg p-1 m-4 font-bold">
              <span>$1400</span>
            </div>
          </div>

          <div className='rounded shadow-green-600  shadow sm:w-48 bg-gray-600 overflow-hidden relative'>
            <img src="image/14.jpeg" alt='' className='w-full h-32 sm:h-48 object-cover'/>
            <div className="justify-between font-semibold text-white ">
              <p className="uppercase">Luca</p>
              <p>Ayahuasca</p>
            </div>
            <audio controls className="h-10 w-full p-2">
            <source src="audio/14.mpeg"/>
            </audio>
            <div className="p-2">
              <Link to="/Sold"> 
                <button className="bg-orange-600 font-bold rounded-lg text-black px-4 p-2 mb-2">SOLD</button>
              </Link>
            </div>
            <div className="absolute top-0 bg-white rounded-lg p-1 m-4 font-bold">
              <span>$1500</span>
            </div>
          </div>

          <div className='rounded shadow-green-600  shadow sm:w-48 bg-gray-600 overflow-hidden relative'>
            <img src="image/15.jpeg" alt='' className='w-full h-32 sm:h-48 object-cover'/>
            <div className="justify-between font-semibold text-white ">
              <p className="uppercase">Lovely</p>
              <p>Damn</p>
            </div>
            <audio controls className="h-10 w-full p-2">
            <source src="audio/15.mpeg"/>
            </audio>
            <div className="p-2">
              <Link to="/Buy">
              <button className="bg-green-600 font-bold rounded-lg text-black px-4 p-2 mb-2">BUY</button>
              </Link> 
            </div>
            <div className="absolute top-0 bg-white rounded-lg p-1 m-4 font-bold">
              <span>$1900</span>
            </div>
          </div>

          <div className='rounded shadow-green-600  shadow sm:w-48 bg-gray-600 overflow-hidden relative'>
            <img src="image/16.jpeg" alt='' className='w-full h-32 sm:h-48 object-cover'/>
            <div className="justify-between font-semibold text-white ">
              <p className="uppercase">dannybling</p>
              <p>Saveon</p>
            </div>
            <audio controls className="h-10 w-full p-2">
            <source src="audio/16.mpeg"/>
            </audio>
            <div className="p-2">
              <Link to="/Buy"> 
                <button className="bg-green-600 font-bold rounded-lg text-black px-4 p-2 mb-2">BUY</button>
              </Link>
            </div>
            <div className="absolute top-0 bg-white rounded-lg p-1 m-4 font-bold">
              <span>$1550</span>
            </div>
          </div>

          <div className='rounded shadow-green-600  shadow sm:w-48 bg-gray-600 overflow-hidden relative'>
            <img src="image/17.jpeg" alt='' className='w-full h-32 sm:h-48 object-cover'/>
            <div className="justify-between font-semibold text-white ">
              <p className="uppercase">BOY-SPI</p>
              <p>Megathron</p>
            </div>
            <audio controls className="h-10 w-full p-2">
            <source src="audio/17.mpeg"/>
            </audio>
            <div className="p-2">
              <Link to="/Sold"> 
                <button className="bg-orange-600 font-bold rounded-lg text-black px-4 p-2 mb-2">SOLD</button>
              </Link>
            </div>
            <div className="absolute top-0 bg-white rounded-lg p-1 m-4 font-bold">
              <span>$1200</span>
            </div>
          </div>

          <div className='rounded shadow-green-600  shadow sm:w-48 bg-gray-600 overflow-hidden relative'>
            <img src="image/18.jpeg" alt='' className='w-full h-32 sm:h-48 object-cover'/>
            <div className="justify-between font-semibold text-white ">
              <p className="uppercase">Lubstar</p>
              <p>Define</p>
            </div>
            <audio controls className="h-10 w-full p-2">
            <source src="audio/18.mpeg"/>
            </audio>
            <div className="p-2">
              <Link to="/Buy"> 
              <button className="bg-green-600 font-bold rounded-lg text-black px-4 p-2 mb-2">BUY</button>
              </Link>
            </div>
            <div className="absolute top-0 bg-white rounded-lg p-1 m-4 font-bold">
              <span>$1700</span>
            </div>
          </div>



        </div>
      </div>
      <div className="text-white p-10 z-10 justify-center text-center">
        <h1 className="text-2xl font-serif oldstyle-nums text-yellow-300 my-10 font-bold">LATTEST COLLECTIONS</h1>
        <div className="justify-items-center justify-center text-center pt-2 grid gap-5 md:grid md:gap-10 md:px-40 lg:px-33">
            <div className='rounded shadow-white shadow  bg-black  overflow-hidden'>
                <video controls >
                <source src="video/vid1.mp4"/>
                </video>
                <div className="flex font-bold  justify-between m-2 text-black">
                    <p className="p-2 rounded-lg bg-gray-600">JAMES</p>
                    <p className="p-2 rounded-lg bg-gray-600">Hot-Kit</p>
                    <p className="p-2 rounded-lg bg-red-600">$1900</p>
                </div>
                <Link to="/Buy">
                <button className="bg-green-600 font-bold rounded-lg text-black px-4 p-2 mb-2">BUY</button>
                </Link>
            </div>

            <div className='rounded shadow-white shadow md:w-120 bg-black  overflow-hidden'>
                <video controls >
                <source src="video/vid2.mp4"/>
                </video>
                <div className="flex font-bold  justify-between m-2 text-black">
                    <p className="p-2 rounded-lg bg-gray-600">WAIVE</p>
                    <p className="p-2 rounded-lg bg-gray-600">SounDown</p>
                    <p className="p-2 rounded-lg bg-red-600">$2600</p>
                </div>
                <Link to="/Buy">
                <button className="bg-green-600 font-bold rounded-lg text-black px-4 p-2 mb-2">BUY</button>
                </Link>
            </div>

            <div className='rounded shadow-white shadow  bg-black  overflow-hidden'>
                <video controls >
                <source src="video/vid3.mp4"/>
                </video>
                <div className="flex font-bold  justify-between m-2 text-black">
                    <p className="p-2 rounded-lg bg-gray-600">NONI</p>
                    <p className="p-2 rounded-lg bg-gray-600">EndLess</p>
                    <p className="p-2 rounded-lg bg-red-600">$2300</p>
                </div>
                <button className="bg-orange-600 font-bold rounded-lg text-black px-4 p-2 mb-2">SOLD</button>
            </div>

            <div className='rounded shadow-white shadow md:w-120 bg-black  overflow-hidden'>
                <video controls >
                <source src="video/vid4.mp4"/>
                </video>
                <div className="flex font-bold  justify-between m-2 text-black">
                    <p className="p-2 rounded-lg bg-gray-600">Niclas</p>
                    <p className="p-2 rounded-lg bg-gray-600">Darkness</p>
                    <p className="p-2 rounded-lg bg-red-600">$1400</p>
                </div>
                <Link to="/B">
                <button className="bg-orange-600 font-bold rounded-lg text-black px-4 p-2 mb-2">SOLD</button>
                </Link>
            </div>
        </div>
      </div>
    </div>
    
    <Footer/>
    </div>
  );
}

export default Home;
