import React from 'react'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

function Buy() {
  return (
    <div className='h-screen '>
        <Navbar/>
        <div className='bg-black text-black text-left justify-items-center p-10'>
            <div className='bg-white  justify-center p-2 rounded-lg'>
                <p className=' text-center underline font-bold'>MAKE PAYMENT</p>
                <div className=' p-2 gap-4'>
                    <div className='flex h-1/3 gap-1 bg-g'>
                        <div className='w-1/2 overflow-hidden'>                        
                            <img src="image/visa.png" alt='' className='object-cover'/>
                        </div>
                        <div className='w-1/2 overflow-hidden'>                  
                            <img src="image/paypal.png" alt='' className='object-cover '/>
                        </div>                                          
                    </div>

                    <div className='h-2/3'>
                        <div>
                            <p>Card Number</p>
                            <input className='border w-full h-10 p-2 rounded-md'/>
                        </div>
                    
                        <div className='flex justify-between'>
                            <div className=''>
                                <p>Ex.date</p>
                                <input className='border h-10 rounded-md'/>
                            </div>
                            <div className=''>
                                <p>Cvv</p>
                                <input className='border w-16 pl-2 h-10 rounded-md'/>
                            </div>
                        </div>

                        <div className='justify-center justify-items-center w-full pt-5 pb-5'>
                            <button className='bg-orange-600 w-full rounded-lg h-10'>comfirm payment</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      <Footer/>
    </div>
  )
}

export default Buy;