import React from 'react'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

function Terms() {
  return (
    <div className='h-screen '>
      <Navbar/>
        <div className='bg-black text-white text-center shadow-2xl shadow-white px-10 py-20 border-yellow-100'>
            <h1 className='font-bold underline text-2xl pb-5'>TERMS</h1>
            <p>PLEASE READ THESE TERMS OF SERVICE CAREFULLY. THEY CONTAIN THE LEGALLY BINDING TERMS AND CONDITIONS FOR YOUR USE OF OUR SERVICE, INCLUDING THE PURCHASE AND SALE OF NFT CONTENT AND OTHER SERVICES PROVIDED THROUGH OUR PLATFORM. THESE TERMS REQUIRE YOU TO ARBITRATE ANY DISPUTES WITH US, AND YOU WAIVE YOUR ABILITY TO BRING CLAIMS AGAINST US IN A CLASS ACTION FORMAT. BY USING THE SERVICE, YOU ACKNOWLEDGE AND ACCEPT THE TUNIVERSE CODE OF CONDUCT. 
            THIS AGREEMENT INCLUDES THE PROPER VERIFICATION OF NFT SELLERS BEFORE PAYMENT IS MADE TO AVOID PROPERTY THEFT AND COPYRIGHT ISSUES RELATED TO NFTs SOLD ON OUR PLATFORM. BY AGREEING TO SELL ON OUR PLATFORM, WHETHER AS A THIRD-PARTY SELLER OR IN PERSON, YOU ACKNOWLEDGE OUR RIGHT TO PROPERLY VERIFY OWNERSHIP OF THE PROPERTY.</p>
        </div>
      <Footer/>
    </div>
  )
}

export default Terms;