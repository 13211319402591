import React from 'react'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

function Register() {
  return (
    <div className='h-screen '>
      <Navbar/>
        <div className='bg-black text-white text-center  px-24 py-32 '>
            <h1 className='font-bold underline text-2xl pb-5'>REGISTRATION</h1>
            <p>Registration is currently available only for existing customers. Please contact our customer service for more information.</p>
        </div>
      <Footer/>
    </div>
  )
}

export default Register;