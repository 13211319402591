import React, {useState, } from 'react'
import { AiOutlineClose, AiOutlineMenu, } from "react-icons/ai";
import { Link } from 'react-router-dom';
import { FaFire } from "react-icons/fa6";


const Navbar = () => {

  const list =["Home","Contact","About","Register","Privacy","Terms"]
  const [Nav, setNav] = useState(false)

    function handleClick ()  {
      setNav(!Nav)
    }

  return (
    <div className='flex justify-around bg-gray-300 mx-auto'>
        <h1 className ='text-4xl w-full font-bold text-center p-4 font-serif'>T-Universe</h1>
            <ul className='hidden lg:flex flex-row gap-2'>
                {
                    list.map((item) => (
                        <li className='py-5 px-8 rounded text-black bold'>
                            {item}
                        </li>
                    ))
                }
            </ul>
            
            
            <div onClick={handleClick} className=' md:hidden p-4 flex'>
              {Nav ? <AiOutlineClose size={35}/> : <AiOutlineMenu size={35}/>}
            </div>
            <div className= {Nav ? 'fixed bg-gray-200 z-20 left-0 top-0 w-[80%] h-full border-r border-r-gray-600 ease-in-out duration-500 lg:hidden': 'fixed left-[-100%] '}>
              <h1 className='text-4xl w-full underline font-bold text-center p-4'>T-Universe.</h1>
              <ul className='uppercase '>
              <Link to="/Home">
                <li className='p-4 border-b border-gray-600' >Home</li>
                </Link>
                <Link to="/Contact">
                <li className='p-4 border-b border-gray-600' >Contact</li>
                </Link>
                <Link to="/About">
                <li className='p-4 border-b border-gray-600' >About</li>
                </Link>
                <Link to="/Privacy">
                <li className='p-4 border-b border-gray-600' >Privacy</li>
                </Link>
                <Link to="/Terms">
                <li className='p-4 border-b border-gray-600' >Terms</li>
                </Link>
                <Link to="/Register">
                <li className='p-4 border-b border-gray-600' >Register</li>
                </Link>
              </ul>
              <div className='z-20 pt-4 bg-gray-300 h-full'>
                <div className='pl-4'>                
                  <h2 className='text-sm font-san'>Currently, registration is only available for<br/>pre-existing customers due to site <br/>bandwidth lsimitations.<br/>New customers will be able to register <br/>and start selling on April 22. Please contact our <br/>customer service for more information.</h2>
                  <div className='pt-5'>
                    <Link to="/Reg">
                    <button className='bg-green-500 py-2 px-4 rounded-lg font-bold mt-5 text-orange-'>Start Selling</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
    </div>
  )
}

export default Navbar;