import React from 'react'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

function Privacy() {
  return (
    <div className='h-screen '>
      <Navbar/>
        <div className='bg-black text-white justify-items-center text-center shadow-2xl shadow-white px-24 py-20 border-yellow-100'>
            <h1 className='font-bold underline text-2xl pb-5'>PRIVACY</h1>
            <p> All information is confidential and follows our guidelines for transparency and accountability.</p>
        </div>
      <Footer/>
    </div>
  )
}

export default Privacy;