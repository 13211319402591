import React from 'react'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

function Reg () {
  return (
    <div className='h-screen '>
      <Navbar/>
        <div className='bg-black text-white text-center  px-24 py-32 '>
            <h1 className='font-bold underline text-2xl pb-5'>REGISTRATION</h1>
            <p>Registration is only available for Pre-existing customers at the moment due to site bandwidth and overload. New customers will be able to register and start selling from February 22. Contact our customer service for more information.........</p>
        </div>
      <Footer/>
    </div>
  )
}

export default Reg;